var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('md-card',[_c('md-card-header',{attrs:{"data-background-color":"green"}},[_c('h4',{staticClass:"title"},[_c('b',[_vm._v("INGRESOS REALIZADOS HASTA LA FECHA")])])]),_c('md-card-content',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.lista_ficha.filter(
                (data) =>
                  !_vm.search ||
                  data.rut.toLowerCase().includes(_vm.search.toLowerCase())
              )}},[_c('el-table-column',{attrs:{"label":"Detalles","type":"expand","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[_c('div',[_c('table',[_c('thead',{},[_c('tr',[_c('th',{attrs:{"scope":"col","width":"150"}},[_vm._v("Ficha")]),_c('th',{attrs:{"scope":"col","width":"300"}},[_vm._v("Fecha de Ingreso")]),_c('th',{attrs:{"scope":"col","width":"600"}},[_vm._v("Motivo Consulta")]),_c('th',{attrs:{"scope":"col","width":"600"}},[_vm._v("Diagnóstico")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"scope":"col","width":"400"}},[_vm._v(" Opciones ")])])]),_c('tbody',[_vm._l((scope.row.detalle_ficha),function(lista){return [_c('tr',{key:lista.id_detalle_ficha},[_c('th',{staticClass:"th_ficha"},[_vm._v(" "+_vm._s(lista.id_detalle_ficha)+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.formatDate2(lista.fecha_ingreso))+" ")]),_c('th',{staticClass:"th_motivo_diag"},[_vm._v(" "+_vm._s(lista.motivo_consulta)+" ")]),_c('th',{staticClass:"th_motivo_diag"},[_vm._v(" "+_vm._s(lista.diagnostico)+" ")]),_c('th',[_c('button',{staticClass:"btn btn-outline-warning",attrs:{"title":"Editar"},on:{"click":function($event){_vm.limpiar2(),
                                    _vm.editarDetalleFicha(
                                      scope.row.rut,
                                      lista.id_detalle_ficha,
                                      lista.fecha_ingreso,
                                      scope.row.nombre
                                    )}}},[_vm._v(" Editar "),_c('i',{staticClass:"far fa-edit"})]),_c('button',{staticClass:"btn btn-outline-success",attrs:{"title":"Ver"},on:{"click":function($event){_vm.limpiar(),
                                    _vm.abrirDetalleFicha(
                                      scope.row.rut,
                                      lista.id_detalle_ficha,
                                      lista.fecha_ingreso,
                                      scope.row.nombre
                                    )}}},[_vm._v(" Ver "),_c('i',{staticClass:"fa fa-eye"})])])])]})],2)])])]]}}])}),_c('el-table-column',{attrs:{"prop":"rut","label":"Rut","width":"120"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"nombre","label":"Nombre","width":"400"}}),_c('el-table-column',{attrs:{"width":"160","prop":"fecha_nacimiento","label":"Fecha de nacimiento"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.formatDate2(scope.row.fecha_nacimiento))+" ")]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-input',{attrs:{"placeholder":"Ingrese Rut"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}}])})],1)],1)],1)],1)]),_c('div',{staticClass:"container"},[_c('modalDetalleFicha',{ref:"modalDetalleFicha"}),_c('modalEditarFicha',{ref:"modalEditarFicha"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }