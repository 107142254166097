var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('md-card',[_c('md-card-header',{attrs:{"data-background-color":"green"}},[_c('h4',{staticClass:"title"},[_c('b',[_vm._v("PACIENTES REGISTRADOS")])])]),_c('md-card-content',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.lista_paciente.filter(
                (data) =>
                  !_vm.search ||
                  data.rut.toLowerCase().includes(_vm.search.toLowerCase())
              )}},[_c('el-table-column',{attrs:{"prop":"rut","label":"Rut","width":"120"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"nombre","label":"Nombre","width":"500"}}),_c('el-table-column',{staticClass:"w-100",attrs:{"prop":"fecha_nacimiento","label":"Fecha de nacimiento"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.formatDate(scope.row.fecha_nacimiento))+" ")]}}])}),_c('el-table-column',{attrs:{"align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-input',{attrs:{"placeholder":"Ingrese Rut"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}},{key:"default",fn:function(scope){return [_c('button',{staticClass:"btn btn-outline-warning",attrs:{"title":"Editar"},on:{"click":function($event){_vm.limpiar2(),
                      _vm.editarDetallePaciente(
                        scope.row.id_paciente,
                        scope.row.id_genero,
                        scope.row.id_estado_civil
                      )}}},[_vm._v(" Editar "),_c('i',{staticClass:"far fa-edit"})]),_c('button',{staticClass:"btn btn-outline-success",attrs:{"title":"Ver"},on:{"click":function($event){_vm.limpiar(), _vm.abrirDetallePaciente(scope.row.id_paciente)}}},[_vm._v(" Ver "),_c('i',{staticClass:"fa fa-eye"})])]}}])})],1)],1)],1)],1)]),_c('div',{staticClass:"container"},[_c('modalDetallePaciente',{ref:"modalDetallePaciente"}),_c('modalEditarPaciente',{ref:"modalEditarPaciente"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }