 <template>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <form class="formulario2">
      <p style="color: rgb(92, 88, 88)" class="navbar-brand etiqueta">
        Bienvenido
      </p>
    </form>
    <button class="btn btn-danger boton" @click="logout()">
      <i class="fas fa-power-off"> Cerrar sessión</i>
    </button>
  </nav>
</template>
<script>
import swal from "sweetalert2";
import { ref } from "vue";
import axios from "axios"; 
import { mapState } from "vuex";

window.Swal = swal;
export default {
  data() {
    return {
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },


  },

  mounted() {
  },

  computed: {
    ...mapState(["server_api", "auth"]),
  },
};
</script>
<style>
.formulario2 {
  top: 20px;
  margin-inline-start: 30px;
}
.etiqueta {
  text-transform: capitalize;
  color: rgb(92, 88, 88);
  font-weight: bold;
}
.boton {
  top: 20px;
  margin-inline-end: 30px;
}

.navbar {
  float: right;
  height: 30px;
}
</style>
