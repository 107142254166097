<template>
  <div style=" width=200px">
    <el-dialog
      title="Editar datos de Ficha"
      :visible.sync="modalSync"
      width="70%"
      top="10px"
      :before-close="lostFocusModal"
    >
      <b
        ><span> Ficha N°{{ id_detalle_ficha }} </span> <br />
        <span> Rut: {{ rut }} </span> <br />
        <span>Paciente: {{ nombre }}</span
        ><br />
        <span>Fecha de atención: {{ formatDate(fecha_ingreso) }} </span></b
      >

      <!-- ----------------------------------------------------------------------------------- -->
      <div
        style=" width=200px min-height: 800px; max-height: 800px; overflow-x: hidden"
        v-loading="loading"
      >
        <br />
        <div class="card" id="form-modal">
          <div class="card-body">
            <div class="card col">
              <div class="card-body">
                <div class="col-md-12">
                  <b
                    ><label class="form-label">1 .- MOTIVO CONSULTA: </label
                    ><el-input
                      type="textarea"
                      id="text_area"
                      v-model="motivo_consulta"
                    ></el-input
                  ></b>
                </div>
                <!-- <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >2 .- ENFERMEDADES PERSONALES Y/O FAMILIARES: </label
                    ><el-input
                      v-model="enfermedades_personales"
                      id="text_area_antecedentes"
                      type="textarea"
                    >
                    </el-input
                  ></b>
                </div> -->
                <div class="col-md-12">
                  <b
                    ><label class="form-label">2 .- CONSUMO: </label>
                    <el-input
                      id="text_area_antecedentes"
                      type="textarea"
                      v-model="consumo"
                    ></el-input
                  ></b>
                </div>
                <!-- <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >4 .- EXPERIENCIAS TRAUMÁTICAS, ACCIDENTES, OTROS: </label
                    ><el-input
                      v-model="experiencias_tra_acc_otros"
                      id="text_area_antecedentes"
                      type="textarea"
                    >
                    </el-input
                  ></b>
                </div> -->
                <!-- <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >5 .- ANTECEDENTES DE SALUD: </label
                    ><el-input
                      v-model="antecedentes_salud"
                      id="text_area_antecedentes"
                      type="textarea"
                    >
                    </el-input
                  ></b>
                </div> -->
                <div class="col-md-12">
                  <b
                    ><label>3 .- ANAMNESIS: </label>
                    <el-input
                      type="textarea"
                      id="anamnesis"
                      v-model="anamnesis"
                    ></el-input
                  ></b>
                </div>
                <div class="col-md-12">
                  <b
                    ><label>4 .- DIAGNOSTICO: </label>
                    <el-input
                      type="textarea"
                      id="text_area"
                      v-model="diagnostico"
                    ></el-input
                  ></b>
                </div>
                <!-- <div class="col-md-12">
                  <b
                    ><label>8 .- INDICACIONES: </label
                    ><el-input
                      type="textarea"
                      id="text_area"
                      v-model="indicaciones"
                    ></el-input
                  ></b>
                </div> -->
                <div class="col-md-12">
                  <b
                    ><label>5 .- TRATAMIENTO: </label>
                    <el-input
                      type="textarea"
                      id="text_area"
                      v-model="tratamiento"
                    ></el-input
                  ></b>
                </div>
                <div class="col-md-12">
                  <b
                    ><label>6.- EXAMENES SOLICITADOS: </label>
                    <el-input
                      type="textarea"
                      id="text_area"
                      v-model="examenes"
                    ></el-input
                  ></b>
                </div>
                <div align="right"  class="col-md-12">
                  <md-button
                    class="md-raised md-danger "
                    @click="lostFocusModal()"
                  >
                    Salir
                  </md-button>
                  <md-button
                    class="md-raised md-success "
                    @click="editarDetallesFicha()"
                  >
                    Editar
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import swal from "sweetalert2";
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      modalSync: false,
      confirma: false,
      loading: true,
      rut: "",
      detalle_fichas: [],
      motivo_consulta: "",
      /* enfermedades_personales: "", */
      consumo: "",
     /*  experiencias_tra_acc_otros: "", */
      /* antecedentes_salud: "", */
      anamnesis: "",
      diagnostico: "",
      /* indicaciones: "", */
      tratamiento: "",
      examenes: "",
      fecha_ingreso: "",
      id_detalle_ficha: "",
      nombre: "",
      id_paciente: "",
      lista_errores: [],

    };
  },

  methods: {
    openLoading() {
      this.$vs.loading();
    },
    closeLoading() {
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    editarDetallesFicha() {
      let me = this;
      me.openLoading();
      return new Promise((resolve, reject) => {
        axios
          .put(
            this.server_api + "/editar_detalle_ficha",
            {
              id_detalle_ficha: this.id_detalle_ficha,
              id_paciente: this.id_paciente,
              motivo_consulta: this.motivo_consulta,
              /* enfermedades_personales: this.enfermedades_personales, */
              consumo: this.consumo,
              /* experiencias_tra_acc_otros: this.experiencias_tra_acc_otros, */
              /* antecedentes_salud: this.antecedentes_salud, */
              anamnesis: this.anamnesis,
              diagnostico: this.diagnostico,
              /* indicaciones: this.indicaciones, */
              tratamiento: this.tratamiento,
              examenes: this.examenes,
            },
            {
              headers: {
                Authorization: `Bearer ${this.auth.access_token}`,
              },
            }
          )
          .then(function (response) {            
            Swal.fire({
              /* target: document.getElementById("form-modal"), */
              icon: "success",
              title: "¡ Exito !",
              text: response.data,
            });            
            resolve(response);            
            me.$parent.obtenerListaFicha();            
            me.cerrarModal();
          })
          .catch(function (error) {
            me.closeLoading();
            Swal.fire({
              target: document.getElementById("form-modal"),
              icon: "error",
              title: "¡ Error !",
              text: "Se ha producido un error, vuelva a intentarlo más tarde",
            });
            reject(error);
          });
           me.closeLoading();
      });
    },
    obtenerDetalleFicha(rut, id_detalle_ficha) {
      let me = this;
      me.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .get(
            this.server_api +
              "/obtener_detalle_ficha?rut=" +
              rut +
              "&id_detalle_ficha=" +
              id_detalle_ficha,
            {
              headers: {
                Authorization: `Bearer ${this.auth.access_token}`,
              },
            }
          )
          .then((response) => {
            //this.detalle_fichas = response.data;

            //   console.log("array "+this.detalle_fichas.length)
            me.motivo_consulta = response.data[0].motivo_consulta;
            /* me.enfermedades_personales =
              response.data[0].enfermedades_personales; */
            me.consumo = response.data[0].consumo;
            /* me.experiencias_tra_acc_otros =
              response.data[0].experiencias_tra_acc_otros; */
            /* me.antecedentes_salud = response.data[0].antecedentes_salud; */
            me.anamnesis = response.data[0].anamnesis;
            me.diagnostico = response.data[0].diagnostico;
            /* me.indicaciones = response.data[0].indicaciones; */
            me.tratamiento = response.data[0].tratamiento;
            me.examenes = response.data[0].examenes;
            me.id_detalle_ficha = response.data[0].id_detalle_ficha;
            me.id_paciente = response.data[0].id_paciente;
            me.loading = false;
          })
          .catch(function (error) {
            me.loading = false;
            Swal.fire({
              target: document.getElementById("form-modal"),
              icon: "error",
              title: "¡ Error !",
              text: "Se ha producido un error, vuelva a intentarlo más tarde",
            });
            reject(error);
            
          });
      });
    },
    lostFocusModal(done) {
      let me = this;
      this.$confirm("Se cerrará módulo")
        .then((_) => {
          this.cerrarModal();
          done();
        })
        .catch((_) => {});
    },

    cerrarModal() {
      this.modalSync = false;
    },

    
  },

  computed: {
    formattedDate() {
      return this.formatDate(this.date);
    },
    ...mapState(["server_api", "auth"]),
  },
  mounted() {
    // this.obtenerDetalleFicha(this.rut);
  },
};
</script>
<style>
.contenedor {
  justify-content: right;
}
.right {
  float: right;
}
.left {
  float: left;
}
#anamnesis {
  height: 200px;
}
</style>
