<template>
  <div style=" width=200px">
    <el-dialog
      title="Datos de Paciente"
      :visible.sync="modalSync"
      width="70%"
      top="10px"
      :before-close="lostFocusModal"
    >
      <b>
        <h3>Rut: {{ rut }}</h3>
        <h3>Paciente: {{ nombre }}</h3></b
      >

      <!-- ----------------------------------------------------------------------------------- -->
      <div
        style=" width=200px min-height: 800px; max-height: 800px; overflow-x: hidden"
        v-loading="loading"
      >
        <br />
        <div class="card" id="form-modal">
          <div class="card-body">
            <div class="card col">
              <div class="card-body" style=" width=400px">
                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >- FECHA DE NACIMIENTO:
                      {{ formatDate(fecha_nacimiento) }}</label
                    ></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- EDAD: {{ edad }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- PARTO DE TERMINO: {{ parto_termino }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- PARTOS PREMATUROS: {{ parto_prematuro }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- ABORTOS: {{ abortos }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- MORTINATO: {{ mortinato }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- HIJOS VIVOS: {{ hijos_vivos }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- PESO: {{ peso }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- TALLA: {{ talla }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- IMC: {{ imc }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- PRESION: {{ presion }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >- DIRECCIÓN: {{ direccion }}</label
                    ></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >- TELEFONO: {{ telefono }}</label
                    ></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label">- EMAIL: {{ email }}</label></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >- GÉNERO: {{ nombre_genero }}</label
                    ></b
                  >
                </div>

                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >- ESTADO CIVIL: {{ nombre_estado }}</label
                    ></b
                  >
                </div>
              </div>
              <div class="float-right">
                <md-button
                  class="md-raised md-danger"
                  @click="lostFocusModal()"
                >
                  Salir
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import swal from "sweetalert2";
import { mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      modalSync: false,
      loading: true,
      detalle_pacientes: [],
      id_paciente: "",
      rut: "",
      nombre: "",
      fecha_nacimiento: "",
      edad: "",
      /* hijos: "",
      cantidad_hijos: "", */
      profesion: "",
      peso: "",
      presion:"",
      talla: "",
      imc: "",
      direccion: "",
      telefono: "",
      email: "",
      id_genero: "",
      id_estado_civil: "",
      nombre_genero: "",
      nombre_estado: "",
      /* aborto: "", */
      parto_termino: 0,
      parto_prematuro: 0,
      abortos: 0,
      mortinato: 0,
      hijos_vivos: 0,

      date: "",
    };
  },

  methods: {
    openLoading() {
      this.$vs.loading();
    },
    closeLoading() {
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },

    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },

    //se llama a paciente seleccionado en página principal
    obtenerDetallePaciente(id_paciente) {
      let me = this;
      me.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .get(
            this.server_api +
              "/obtener_detalle_paciente?id_paciente=" +
              id_paciente,
            {
              headers: {
                Authorization: `Bearer ${this.auth.access_token}`,
              },
            }
          )
          .then((response) => {
            me.nombre = response.data[0].nombre;
            me.rut = response.data[0].rut;
            me.fecha_nacimiento = response.data[0].fecha_nacimiento;
            me.profesion = response.data[0].profesion;
            me.edad = response.data[0].edad;
            me.peso = response.data[0].peso;
            me.presion = response.data[0].presion;
            me.talla = response.data[0].talla;
            me.hijos = response.data[0].hijos;
            me.aborto = response.data[0].aborto;
            me.imc = response.data[0].imc;
            me.direccion = response.data[0].direccion;
            me.telefono = response.data[0].telefono;
            me.email = response.data[0].email;
            me.nombre_genero = response.data[0].nombre_genero;
            me.nombre_estado = response.data[0].nombre_estado;
            me.parto_termino = response.data[0].parto_termino;
            me.parto_prematuro = response.data[0].parto_prematuro;
            me.abortos = response.data[0].abortos;
            me.mortinato = response.data[0].mortinato;
            me.hijos_vivos = response.data[0].hijos_vivos;
            console.log(response.data);

            if (
              response.data[0].cantidad_hijos == null ||
              response.data[0].cantidad_hijos == ""
            ) {
              me.cantidad_hijos = "Sin Hijos";
            } else {
              me.cantidad_hijos = response.data[0].cantidad_hijos;
            }
            me.loading = false;
          })
          .catch(function (error) {
            me.loading = false;
            Swal.fire({
              target: document.getElementById("form-modal"),
              icon: "error",
              title: "¡ Error !",
              text: "Se ha producido un error, vuelva a intentarlo más tarde",
            });
            reject(error);
          });
      });
    },
    lostFocusModal(done) {
      let me = this;
      this.$confirm("Se cerrará módulo")
        .then((_) => {
          this.cerrarModal();
          done();
        })
        .catch((_) => {});
    },

    cerrarModal() {
      this.modalSync = false;
    },
  },

  computed: {
    formattedDate() {
      return this.formatDate(this.date);
    },
    ...mapState(["server_api", "auth"]),
  },
  mounted() {
    //  this.obtenerDetalleFicha(this.rut);
  },
};
</script>
<style>
.contenedor {
  justify-content: right;
}
.right {
  float: right;
}
.left {
  float: left;
}
</style>
