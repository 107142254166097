<template>
  <div style=" width=200px">
    <el-dialog
      title="Editar datos de Paciente"
      :visible.sync="modalSync"
      width="70%"
      top="10px"
      :before-close="lostFocusModal"
    >
      <b>
        <h3>Rut: {{ rut }}</h3>
        <h3>Paciente: {{ nombre }}</h3></b
      >

      <!-- ----------------------------------------------------------------------------------- -->
      <div
        style=" width=200px min-height: 800px; max-height: 800px; overflow-x: hidden"
        v-loading="loading"
      >
        <br />
        <div class="card" id="form-modal">
          <div class="card-body">
            <div class="card col">
              <div class="card-body">
                <div class="col-md-12">
                  <label class="form-label">RUT </label>
                  <el-input
                    v-model="rut"
                    maxlength="10"
                    clearable
                    @keyup.native="formatRut"
                  >
                  </el-input>
                  <p class="text-danger">{{ rut_error }}</p>
                </div>
                <div class="col-md-12">
                  <label class="form-label">NOMBRE COMPLETO </label>
                  <el-input v-model="nombre" clearable> </el-input>
                  <p class="text-danger">{{ nombre_error }}</p>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <label class="form-label">FECHA DE NACIMIENTO</label>
                      <div class="row">
                        <el-date-picker
                          class="col-12"
                          v-model="fecha_nacimiento"
                          value-format="DDMMYYYY"
                          type="date"
                          placeholder="Pick a day"
                        >
                        </el-date-picker>
                        <p class="text-danger">{{ fecha_nacimiento_error }}</p>
                      </div>
                    </div>
                    <div class="col-2">
                      <label class="form-label">EDAD</label>
                      <el-input v-model="edad" clearable> </el-input>
                      <p class="text-danger">{{ edad_error }}</p>
                    </div>
                    <div class="col-4">
                      <label class="form-label">GÉNERO</label>
                      <div class="row">
                        <el-select
                          v-model="id_genero"
                          placeholder="Select"
                          class="col-12"
                        >
                          <el-option
                            v-for="item in genero"
                            :key="item.id_genero"
                            :label="item.nombre_genero"
                            :value="item.id_genero"
                          >
                          </el-option>
                        </el-select>
                        <p class="text-danger">{{ edad_error }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-2">
                      <label class="form-label">ESTADO CIVIL</label>
                      <div class="row">
                        <el-select
                          v-model="id_estado_civil"
                          placeholder="Select"
                          class="col-12"
                        >
                          <el-option
                            v-for="item in estado_civil"
                            :key="item.id_estado_civil"
                            :label="item.nombre_estado"
                            :value="item.id_estado_civil"
                          >
                          </el-option>
                        </el-select>
                        <p class="text-danger">{{ id_estado_civil_error }}</p>
                      </div>
                      </div>
                      <div class="col-2">
                        <label class="form-label">PARTO DE TER.</label>
                        <el-input v-model="parto_termino"></el-input>
                      </div>
                      <div class="col-2">
                        <label class="form-label">PARTOS PREM.</label>
                        <el-input v-model="parto_prematuro"></el-input>
                      </div>
                      <div class="col-2">
                        <label class="form-label">ABORTOS</label>
                        <el-input v-model="abortos"></el-input>
                      </div>
                      <div class="col-2">
                        <label class="form-label">MORTINATO</label>
                        <el-input v-model="mortinato"></el-input>
                      </div>
                      <div class="col-2">
                        <label class="form-label">HIJOS VIVOS</label>
                        <el-input v-model="hijos_vivos"></el-input>
                      </div>
                    <!-- <div class="col-2">
                      <label class="form-label">HIJOS</label>
                      <div>
                        <el-radio v-model="hijos" value="1" label="Si"
                          >Si</el-radio
                        >
                        <el-radio v-model="hijos" value="2" label="No"
                          >No</el-radio
                        >
                        <p class="text-danger">{{ hijos_error }}</p>
                      </div>
                    </div> -->
                    <!-- <div class="col-4" v-if="hijos == 'Si'">
                      <label class="form-label">N° DE HIJOS</label>
                      <el-input v-model="cantidad_hijos" clearable> </el-input>
                      <p class="text-danger">{{ cantidad_hijos_error }}</p>
                    </div> -->
                    <!-- <div class="col-4" v-else>
                      <label class="form-label">ABORTO</label>
                      <div class="row">
                        <el-select
                          v-model="aborto"
                          placeholder="Seleccionar"
                          class="col-12"
                        >
                          <el-option
                            v-for="item in opcion_aborto"
                            :key="item.value"
                            :label="item.nombre"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                        <p class="text-danger">{{ aborto_error }}</p>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="form-label">PROFESIÓN</label>
                      <el-input v-model="profesion" clearable> </el-input>
                      <p class="text-danger">{{ profesion_error }}</p>
                    </div>
                    <div class="col-2">
                      <label class="form-label">PESO</label>
                      <el-input v-model="peso" clearable> </el-input>
                      <p class="text-danger">{{ peso_error }}</p>
                    </div>
                    <div class="col-2">
                      <label class="form-label">TALLA</label>
                      <el-input
                        v-model="talla"
                        @keyup.native="formatTalla"
                        clearable
                      >
                      </el-input>
                      <p class="text-danger">{{ talla_error }}</p>
                    </div>
                    <div class="col-2">
                      <label class="form-label">IMC</label>
                      <el-input
                        v-model="imc"
                        @keyup.native="calcularIMC"
                        clearable
                      >
                      </el-input>
                      <p class="text-danger">{{ imc_error }}</p>
                    </div>
                    <div class="col-2">
                      <label class="form-label">PRESION</label>
                      <el-input
                        v-model="presion"
                        maxlength="6"
                        @keyup.native="formatPresion"
                        format
                        clearable
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <label class="form-label">DIRECCIÓN PARTICULAR</label>
                  <el-input v-model="direccion" clearable> </el-input>
                  <p class="text-danger">{{ direccion_error }}</p>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-sm">
                      <label class="form-label">TELEFONO</label>
                      <el-input v-model="telefono" clearable> </el-input>
                      <p class="text-danger">{{ telefono_error }}</p>
                    </div>
                    <div class="col-sm">
                      <label class="form-label">EMAIL</label>
                      <el-input v-model="email" clearable> </el-input>
                      <p class="text-danger">{{ email_error }}</p>
                    </div>
                  </div>
                  <div align="right">
                    <md-button
                      class="md-raised md-danger"
                      @click="lostFocusModal()"
                    >
                      Salir
                    </md-button>
                    <md-button
                      class="md-raised md-success"
                      @click="editarDetallesPaciente()"
                    >
                      Editar
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import swal from "sweetalert2";
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      modalSync: false,
      loading: true,

      opcion_aborto: [
        {
          value: "Si",
          nombre: "Si",
        },
        {
          value: "No",
          nombre: "No",
        },
      ],
      aborto: "",

      estado_civil: [],
      genero: [],
      detalle_pacientes: [],
      id_paciente: "",
      rut: "",
      nombre: "",
      fecha_nacimiento: "",
      edad: "",
      hijos: "",
      cantidad_hijos: "",
      profesion: "",
      peso: "",
      presion: "",
      talla: "",
      imc: "",
      direccion: "",
      telefono: "",
      email: "",
      id_genero: "",
      id_estado_civil: "",
      date: "",

      parto_termino: 0,
      parto_prematuro: 0,
      abortos: 0,
      mortinato: 0,
      hijos_vivos: 0,

      lista_errores: [],

      rut_error: "",
      nombre_error: "",
      fecha_nacimiento_error: "",
      edad_error: "",
      /* hijos_error: "",
      cantidad_hijos_error: "", */
      profesion_error: "",
      peso_error: "",
      /* aborto_error: "", */
      talla_error: "",
      imc_error: "",
      direccion_error: "",
      telefono_error: "",
      email_error: "",
      id_estado_civil_error: "",
      id_genero_error: null,
    };
  },

  methods: {
    //formato presion
    formatPresion() {
      var num = this.presion.replace(/\./g, "");
      console.log(this.presion);
      /* if (num == "") {
        return (this.imc = 0);
      } else if (!parseInt(num)) {
        return (this.imc = 0);
      } else { */
      num = num
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1/");
      num = num.split("").reverse().join("").replace(/^[\.]/, "");
      this.presion = num;
      // }
    },
    /*   calculo imc segun datos registrados */
    calcularIMC() {
      let cambioTalla = this.talla * 100;
      let peso = 0;
      let talla = 0;
      let imc = 0;
      peso = Math.round(this.peso);
      talla = Math.round(cambioTalla) / 100;
      imc = peso / Math.pow(talla, 2);

      this.imc = Number(imc.toFixed(1));
    },
    //formato para estatura (talla)
    formatTalla() {
      var num = this.talla.replace(/\./g, "");
      console.log(this.imc);
      if (num == "") {
        return (this.imc = 0);
      } else if (!parseInt(num)) {
        return (this.imc = 0);
      } else {
        num = num
          .toString()
          .split("")
          .reverse()
          .join("")
          .replace(/(?=\d*\.?)(\d{2})/g, "$1.");
        num = num.split("").reverse().join("").replace(/^[\.]/, "");
        this.talla = num;
        this.calcularIMC();
      }
    },
    checkForm() {
      this.rut_error = "";
      this.nombre_error = "";
      this.fecha_nacimiento_error = "";
      this.id_genero_error = "";
      this.id_estado_civil_error = "";
      this.edad_error = "";
      /* this.hijos_error = "";
      this.cantidad_hijos_error = "";
      this.aborto_error = ""; */
      this.profesion_error = "";
      this.peso_error = "";
      this.talla_error = "";
      this.imc_error = "";
      this.direccion_error = "";
      this.telefono_error = "";
      this.email_error = "";

      let me = this;
      if (
        this.rut &&
        this.nombre &&
        this.fecha_nacimiento &&
        this.id_genero &&
        this.id_estado_civil &&
        this.edad &&
        /* this.hijos &&
        this.cantidad_hijos &&
        this.aborto && */
        this.profesion &&
        this.peso &&
        this.talla &&
        this.imc &&
        this.direccion &&
        this.email
      )
        return true;

      this.lista_errores = [];
      if (!me.rut) {
        this.rut_error = "* El rut es requerido";
        this.lista_errores.push(1);
      } else if (!this.validaRut(this.rut)) {
        this.rut_error = "* El rut ingresado no es válido";
        this.lista_errores.push(1);
      }
      if (!this.nombre) {
        this.nombre_error = "Debe ingresar nombre del paciente";
        this.lista_errores.push(1);
      }
      if (!this.fecha_nacimiento) {
        this.fecha_nacimiento_error =
          "Debe seleccionar, fecha de nacimiento del paciente";
        this.lista_errores.push(1);
      }
      /* if (!this.email) {
        this.email_error = "El email es requerido";
        console.log(!this.email);
        this.lista_errores.push(1);
      } else */
      /* if (this.validEmail(this.email)==false) {
        this.email_error = "El correo electrónico debe ser válido";
        console.log(!this.validEmail(this.email));
        this.lista_errores.push(1);
      } */
      if (!this.id_genero) {
        this.id_genero_error = "Debe seleccionar género del paciente";
        this.lista_errores.push(1);
      }
      if (!this.id_estado_civil) {
        this.id_estado_civil_error =
          "Debe seleccionar estado civil del paciente";
        this.lista_errores.push(1);
      }
      if (!this.edad) {
        this.edad_error = "Debe ingresar la edad del paciente";
        this.lista_errores.push(1);
      }
      if (!parseInt(this.edad)) {
        this.edad_error = "Debe ingresar número";
        this.lista_errores.push(1);
      }
      /* if (!this.hijos) {
        this.hijos_error = "Indique si el paciente tiene hijos";
        this.lista_errores.push(1);
      } */
      /*  if (this.hijos == "Si") {
        if (!this.cantidad_hijos) {
          this.cantidad_hijos_error =
            "Paciente tiene hijos, indique la cantidad";
          this.lista_errores.push(1);
        }
        if (!parseInt(this.cantidad_hijos)) {
          this.cantidad_hijos_error = "Debe ingresar número";
          console.log("no number");
          this.lista_errores.push(1);
        }
      } */
      /*  if (this.hijos == "No") {
        if (!this.aborto) {
          this.aborto_error =
            "Paciente no tienes hijos, indique si ha tenido aborto";
          this.lista_errores.push(1);
        }
      } */
      if (!this.profesion) {
        this.profesion_error = "Debe indicar si el paciente tiene ocupación";
        this.lista_errores.push(1);
      }
      if (!this.imc) {
        this.imc_error = "Debe indicar IMC del paciente";
        this.lista_errores.push(1);
      }
      if (!this.peso) {
        this.peso_error = "Debe indicar el peso del paciente";
        this.lista_errores.push(1);
      }
      if (!parseInt(this.peso)) {
        this.peso_error = "Debe ingresar número";
        this.lista_errores.push(1);
      }
      if (!this.talla) {
        this.talla_error = "Debe indicar talla del paciente";
        this.lista_errores.push(1);
      }
      if (this.talla) {
        let talla = this.talla * 100;
        if (!parseInt(talla)) {
          this.talla_error = "Debe ingresar número";
          this.lista_errores.push(1);
        }
      }
      if (!this.direccion) {
        this.direccion_error = "Debe indicar dirección del paciente";
        this.lista_errores.push(1);
      }
      /* if (!this.telefono) {
        this.telefono_error = "Debe número de teléfono del paciente";
        this.lista_errores.push(1);
      } */

      if (this.lista_errores.length > 0) {
        Swal.fire({
          target: document.getElementById("form-modal"),
          icon: "error",
          title: "¡Atención!",
          text: "Por favor, revise los datos ingresados",
        });
        preventDefault();
      }
    },

    validaRut(rutCompleto) {
      rutCompleto = rutCompleto.replace("‐", "-");
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";

      return this.dv(rut) == digv;
    },
    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },

    formatRut() {
      /*  var validar_rut = this.validaRut();
      if (validar_rut == true) {
        if (
          this.rut.charAt(0) === "k" ||
          this.rut.charAt(0) === "k" ||
          this.rut.charAt(0) === "0"
        ) {
          this.rut = "";
        } */
      let clearRut =
        typeof this.rut === "string"
          ? this.rut.replace(/[^0-9kK]+/g, "").toUpperCase()
          : ""; // limpiamos la variable rut
      if (clearRut.length <= 1) {
        return false;
      }
      var result =
        clearRut.slice(-4, -1) + "-" + clearRut.substr(clearRut.length - 1);
      for (var i = 4; i < clearRut.length; i += 3) {
        result = clearRut.slice(-3 - i, -i) + "" + result;
      }
      this.rut = result;
      let rut = this.rut;
      if (typeof rut !== "string") {
        return false;
      }
      rut =
        typeof this.rut === "string"
          ? this.rut.replace(/[^0-9kK]+/g, "").toUpperCase()
          : "";
      var rutDigits = parseInt(rut.slice(0, -1), 10);
      var m = 0;
      var s = 1;
      while (rutDigits > 0) {
        s = (s + (rutDigits % 10) * (9 - (m++ % 6))) % 11;
        rutDigits = Math.floor(rutDigits / 10);
      }
      var checkDigit = s > 0 ? String(s - 1) : "K";
      if (checkDigit === rut.slice(-1)) {
        this.mensaje_rut = "";
      } else {
        this.mensaje_rut = true;
      }

      var validar_rut = this.validaRut(this.rut);
      if (validar_rut == false) {
        this.div_rut = true;
        this.mensaje_rut = "Rut incompleto o erróneo";
      } else {
        this.div_rut = false;
      }
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    formatMail() {
      var validar_mail = this.validEmail(this.email);
      if (validar_mail == false) {
        this.email_error = "Email incompleto o erróneo";
      } else {
        this.email_error = "";
      }
    },
    openLoading() {
      this.$vs.loading();
    },
    closeLoading() {
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    obtenerEstado() {
      let me = this;
      return new Promise((resolve, reject) => {
        axios
          .get(this.server_api + "/obtener_estado", {
            headers: {
              Authorization: `Bearer ${this.auth.access_token}`,
            },
          })
          .then(function (response) {
            me.estado_civil = response.data;
            resolve(response);
          })
          .catch(function (error) {
            console.log(error.response.data);
            reject(error);
          });
      });
    },
    obtenerGenero() {
      let me = this;
      return new Promise((resolve, reject) => {
        axios
          .get(this.server_api + "/obtener_genero", {
            headers: {
              Authorization: `Bearer ${this.auth.access_token}`,
            },
          })
          .then(function (response) {
            me.genero = response.data;
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    editarDetallesPaciente() {
      if (this.hijos == "No") {
        this.cantidad_hijos = "";
      }
      let me = this;
      this.checkForm();
      me.openLoading();

      return new Promise((resolve, reject) => {
        axios
          .put(
            this.server_api + "/editar_paciente",
            {
              id_genero: this.id_genero,
              id_estado_civil: this.id_estado_civil,
              id_paciente: this.id_paciente,
              rut: this.rut,
              nombre: this.nombre,
              fecha_nacimiento: this.fecha_nacimiento,
              edad: this.edad,
              /* hijos: this.hijos,
              cantidad_hijos: this.cantidad_hijos, */
              profesion: this.profesion,
              /* aborto: this.aborto, */
              peso: this.peso,
              presion: this.presion,
              talla: this.talla,
              imc: this.imc,
              direccion: this.direccion,
              telefono: this.telefono,
              email: this.email,
              parto_termino: this.parto_termino,
              parto_prematuro: this.parto_prematuro,
              abortos: this.abortos,
              mortinato: this.mortinato,
              hijos_vivos: this.hijos_vivos,
            },
            {
              headers: {
                Authorization: `Bearer ${this.auth.access_token}`,
              },
            }
          )
          .then(function (response) {
            Swal.fire({
              /* target: document.getElementById("form-modal"), */
              icon: "success",
              title: "¡ Exito !",
              text: response.data,
            });
            resolve(response);
            me.$parent.obtenerListaPaciente();
            me.cerrarModal();
          })
          .catch(function (error) {
            me.closeLoading();
            Swal.fire({
              target: document.getElementById("form-modal"),
              icon: "error",
              title: "¡ Error !",
              text: "Se ha producido un error, vuelva a intentarlo más tarde",
            });
            reject(error);
          });

        me.closeLoading();
      });
    },
    obtenerDetallePaciente(id_paciente) {
      let me = this;
      me.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .get(
            this.server_api +
              "/obtener_detalle_paciente?id_paciente=" +
              id_paciente,
            {
              headers: {
                Authorization: `Bearer ${this.auth.access_token}`,
              },
            }
          )
          .then((response) => {
            me.nombre = response.data[0].nombre;
            me.rut = response.data[0].rut;
            me.fecha_nacimiento = response.data[0].fecha_nacimiento;
            me.profesion = response.data[0].profesion;
            me.edad = response.data[0].edad;
            me.peso = response.data[0].peso;
            me.presion = response.data[0].presion;
            me.talla = response.data[0].talla;
            /* me.hijos = response.data[0].hijos;
            me.aborto = response.data[0].aborto; */
            me.parto_termino = response.data[0].parto_termino;
            me.parto_prematuro = response.data[0].parto_prematuro;
            me.abortos = response.data[0].abortos;
            me.mortinato = response.data[0].mortinato;
            me.hijos_vivos = response.data[0].hijos_vivos;
            me.imc = response.data[0].imc;
            me.direccion = response.data[0].direccion;
            me.telefono = response.data[0].telefono;
            me.email = response.data[0].email;
            /* me.cantidad_hijos = response.data[0].cantidad_hijos; */
            me.loading = false;
          })
          .catch(function (error) {
            me.loading = false;
            Swal.fire({
              target: document.getElementById("form-modal"),
              icon: "error",
              title: "¡ Error !",
              text: "Se ha producido un error, vuelva a intentarlo más tarde",
            });
            reject(error);
          });
      });
    },
    lostFocusModal(done) {
      let me = this;
      this.$confirm("Se cerrará módulo")
        .then((_) => {
          this.cerrarModal();
          done();
        })
        .catch((_) => {});
    },

    moveUp() {
      window.scrollTo(100, 0);
    },

    cerrarModal() {
      this.modalSync = false;
      this.confirma = false;
      this.id_paciente = "";
      this.rut = "";
      this.nombre = "";
      this.fecha_nacimiento = "";
      this.edad = "";
      this.hijos = "";
      this.cantidad_hijos = "";
      this.profesion = "";
      this.peso = "";
      this.talla = "";
      this.imc = "";
      this.direccion = "";
      this.telefono = "";
      this.email = "";
      this.id_genero = "";
      this.id_estado_civil = "";
    },
  },

  computed: {
    formattedDate() {
      return this.formatDate(this.date);
    },
    ...mapState(["server_api", "auth"]),
  },
  mounted() {
    this.obtenerGenero();
    this.obtenerEstado();
  },
};
</script>
<style>
.contenedor {
  justify-content: right;
}
.right {
  float: right;
}
.left {
  float: left;
}
</style>
