<template>
  <div style=" width=200px">
    <el-dialog
      title="Detalle Ficha"
      :visible.sync="modalSync"
      width="70%"
      top="10px"
      :before-close="lostFocusModal"
    >
      <b
        ><span> Ficha: N°{{ detalle_ficha.id_detalle_ficha }} </span> <br />
        <span> Rut: {{ detalle_ficha.paciente.rut }} </span> <br />
        <span>Paciente: {{ detalle_ficha.paciente.nombre }}</span
        ><br />
        <span
          >Fecha de atención: {{ formatDate(detalle_ficha.fecha_ingreso) }}
        </span></b
      >

      <!-- ----------------------------------------------------------------------------------- -->
      <div
        style=" width=200px min-height: 800px; max-height: 800px; overflow-x: hidden"
      >
        <br />
        <div class="card" id="form-modal">
          <div class="card-body">
            <div class="card col">
              <div class="card-body" style=" width=400px" v-loading="loading">
                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >1 .- MOTIVO CONSULTA:
                      {{ detalle_ficha.motivo_consulta }}</label
                    ></b
                  >
                </div>
                <!-- <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >2 .- ENFERMEDADES PERSONALES Y/O FAMILIARES:
                      {{ enfermedades_personales }}</label
                    ></b
                  >
                </div> -->
                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >3 .- CONSUMO: {{ detalle_ficha.consumo }}</label
                    ></b
                  >
                </div>
                <!-- <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >4 .- EXPERIENCIAS TRAUMÁTICAS, ACCIDENTES, OTROS:
                      {{ experiencias_tra_acc_otros }}</label
                    ></b
                  >
                </div> -->
                <!-- <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >5 .- ANTECEDENTES DE SALUD:
                      {{ antecedentes_salud }}</label
                    ></b
                  >
                </div> -->
                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >6 .- ANAMNESIS: {{ detalle_ficha.anamnesis }}</label
                    ></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >7 .- DIAGNOSTICO: {{ detalle_ficha.diagnostico }}</label
                    ></b
                  >
                </div>
                <!-- <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >8 .- INDICACIONES: {{ indicaciones }}</label
                    ></b
                  >
                </div> -->
                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >9 .- TRATAMIENTO: {{ detalle_ficha.tratamiento }}</label
                    ></b
                  >
                </div>
                <div class="col-md-12">
                  <b
                    ><label class="form-label"
                      >10.- EXAMENES SOLICITADOS:
                      {{ detalle_ficha.examenes }}</label
                    ></b
                  >
                </div>
              </div>
              <div class="float-right">
                <md-button
                  class="md-raised md-danger"
                  @click="lostFocusModal()"
                >
                  Salir
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import swal from "sweetalert2";
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      modalSync: false,
      rut: "",
      detalle: "",
      loading: false,
      detalle_ficha: "",
      motivo_consulta: "",
      /* enfermedades_personales: "", */
      consumo: "",
      /* experiencias_tra_acc_otros: "", */
      /* antecedentes_salud: "", */
      anamnesis: "",
      diagnostico: "",
      /* indicaciones: "", */
      tratamiento: "",
      examenes: "",
      fecha_ingreso: "",
      id_detalle_ficha: "",
      nombre: "",
      date: "",
    };
  },

  methods: {
    openLoading() {
      this.$vs.loading();
    },
    closeLoading() {
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },

    /*    se obteiene detalle de ficha de paciente, en caso contrario, campo se rellena con "sin informacion" */
    obtenerDetalleFicha(rut, id_detalle_ficha) {
      let me = this;
      // me.openLoading();
      me.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .get(
            this.server_api +
              "/obtener_detalle_ficha?rut=" +
              rut +
              "&id_detalle_ficha=" +
              id_detalle_ficha,
            {
              headers: {
                Authorization: `Bearer ${this.auth.access_token}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            if (
              response.data[0].motivo_consulta == "" ||
              response.data[0].motivo_consulta == null
            ) {
              me.motivo_consulta = "Sin Información.";
            } else {
              me.motivo_consulta = response.data[0].motivo_consulta;
            }
            /*  if (response.data[0].enfermedades_personales == null) {
              me.enfermedades_personales = "Sin Información.";
            } else {
              me.enfermedades_personales =
                response.data[0].enfermedades_personales;
            } */
            if (
              response.data[0].consumo == "" ||
              response.data[0].consumo == null
            ) {
              me.consumo = "Sin Información.";
            } else {
              me.consumo = response.data[0].consumo;
            }
            /* if (
              response.data[0].experiencias_tra_acc_otros == null ||
              response.data[0].experiencias_tra_acc_otros == ""
            ) {
              me.consexperiencias_tra_acc_otrosumo = "Sin Información.";
            } else {
              me.experiencias_tra_acc_otros =
                response.data[0].experiencias_tra_acc_otros;
            } */
            /* if (response.data[0].antecedentes_salud == null) {
              me.antecedentes_salud = "Sin Información.";
            } else {
              me.antecedentes_salud = response.data[0].antecedentes_salud;
            } */
            if (response.data[0].anamnesis == null) {
              me.anamnesis = "Sin Información.";
            } else {
              me.anamnesis = response.data[0].anamnesis;
            }
            if (response.data[0].diagnostico == null) {
              me.diagnostico = "Sin Información.";
            } else {
              me.diagnostico = response.data[0].diagnostico;
            }
            /* if (response.data[0].indicaciones == null) {
              me.indicaciones = "Sin Información.";
            } else {
              me.indicaciones = response.data[0].indicaciones;
            } */
            if (response.data[0].tratamiento == null) {
              me.tratamiento = "Sin Información.";
            } else {
              me.tratamiento = response.data[0].tratamiento;
            }
            if (response.data[0].examenes == null) {
              me.examenes = "Sin Información.";
            } else {
              me.examenes = response.data[0].examenes;
            }

            me.loading = false;
          })
          .catch(function (error) {
            me.loading = false;
            Swal.fire({
              target: document.getElementById("form-modal"),
              icon: "error",
              title: "¡ Error !",
              text: "Se ha producido un error, vuelva a intentarlo más tarde",
            });
            reject(error);
          });
      });
    },
    lostFocusModal(done) {
      let me = this;
      this.$confirm("Se cerrará módulo")
        .then((_) => {
          this.cerrarModal();
          done();
        })
        .catch((_) => {});
    },

    cerrarModal() {
      this.modalSync = false;
    },
  },

  computed: {
    formattedDate() {
      return this.formatDate(this.date);
    },
    ...mapState(["server_api", "auth"]),
  },
  mounted() {
    //  this.obtenerDetalleFicha(this.rut);
    console.log(this.detalle_ficha);
  },
};
</script>
<style>
.contenedor {
  justify-content: right;
}
.right {
  float: right;
}
.left {
  float: left;
}
</style>
