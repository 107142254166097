<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title"><b>INGRESOS REALIZADOS HASTA LA FECHA</b></h4>
          </md-card-header>
          <md-card-content>
            <el-table
              v-loading="loading"
              :data="
                lista_ficha.filter(
                  (data) =>
                    !search ||
                    data.rut.toLowerCase().includes(search.toLowerCase())
                )
              "
              style="width: 100%"
            >
              <el-table-column label="Detalles" type="expand" width="80">
                <template slot-scope="scope">
                  <template>
                    <div>
                      <table>
                        <!-- tabla con los detalles de cada ficha que tiene el paciente -->
                        <thead class="">
                          <tr>
                            <th scope="col" width="150">Ficha</th>
                            <th scope="col" width="300">Fecha de Ingreso</th>
                            <th scope="col" width="600">Motivo Consulta</th>
                            <th scope="col" width="600">Diagnóstico</th>
                            <th
                              scope="col"
                              width="400"
                              style="text-align: center"
                            >
                              Opciones
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- for para objeto detalle_ficha que viene con el paciente  desde backend, scope.row es propio de la tabla -->
                          <template v-for="lista in scope.row.detalle_ficha">
                            <tr :key="lista.id_detalle_ficha">
                              <th class="th_ficha">
                                {{ lista.id_detalle_ficha }}
                              </th>
                              <th>
                                {{ formatDate2(lista.fecha_ingreso) }}
                              </th>
                              <th class="th_motivo_diag">
                                {{ lista.motivo_consulta }}
                              </th>
                              <th class="th_motivo_diag">
                                {{ lista.diagnostico }}
                              </th>
                              <th>
                                <!-- se envian datos para el encabezado del modal correspondiente -->
                                <button
                                  class="btn btn-outline-warning"
                                  title="Editar"
                                  @click="
                                    limpiar2(),
                                      editarDetalleFicha(
                                        scope.row.rut,
                                        lista.id_detalle_ficha,
                                        lista.fecha_ingreso,
                                        scope.row.nombre
                                      )
                                  "
                                >
                                  Editar
                                  <i class="far fa-edit"></i>
                                </button>
                                <!-- se envian datos para el encabezado del modal correspondiente -->
                                <button
                                  class="btn btn-outline-success"
                                  @click="
                                    limpiar(),
                                      abrirDetalleFicha(
                                        scope.row.rut,
                                        lista.id_detalle_ficha,
                                        lista.fecha_ingreso,
                                        scope.row.nombre
                                      )
                                  "
                                  title="Ver"
                                >
                                  Ver
                                  <i class="fa fa-eye"></i>
                                </button>
                              </th>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column prop="rut" label="Rut" width="120">
              </el-table-column>
              <el-table-column
                sortable
                prop="nombre"
                label="Nombre"
                width="400"
              >
              </el-table-column>
              <el-table-column
                width="160"
                prop="fecha_nacimiento"
                label="Fecha de nacimiento"
                ><template slot-scope="scope">
                  {{ formatDate2(scope.row.fecha_nacimiento) }}
                </template>
              </el-table-column>
              <el-table-column>
                <template slot="header" slot-scope="scope">
                  <el-input v-model="search" placeholder="Ingrese Rut" />
                </template>
              </el-table-column>
            </el-table>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <div class="container">
      <modalDetalleFicha ref="modalDetalleFicha"> </modalDetalleFicha>
      <modalEditarFicha ref="modalEditarFicha"> </modalEditarFicha>
    </div>
  </div>
</template>

<script>
import ModalDetalleFicha from "@/pages/Modals/ModalDetalleFicha.vue";
import ModalEditarFicha from "@/pages/Modals/ModalEditarFicha.vue";
import { ref } from "vue";
import axios from "axios";
import swal from "sweetalert2";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    modalDetalleFicha: ModalDetalleFicha,
    modalEditarFicha: ModalEditarFicha,
  },
  name: "lista-ingresos",
  data() {
    return {
      search: "",
      lista_ficha: [],
      total_ficha: "",
      modalDetalleFicha: false,
      modalEditarFicha: false,
      date: new Date(),
      fecha_nacimiento: "",
      // loading: "",
      loading: true,
    };
  },

  methods: {
    openLoading() {
      this.$vs.loading();
    },
    closeLoading() {
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    limpiar() {
      (this.$refs.modalDetalleFicha.motivo_consulta = ""),
        (this.$refs.modalDetalleFicha.enfermedades_personales = ""),
        (this.$refs.modalDetalleFicha.consumo = ""),
        (this.$refs.modalDetalleFicha.experiencias_tra_acc_otros = ""),
        (this.$refs.modalDetalleFicha.antecedentes_salud = ""),
        (this.$refs.modalDetalleFicha.anamnesis = ""),
        (this.$refs.modalDetalleFicha.diagnostico = ""),
        (this.$refs.modalDetalleFicha.indicaciones = ""),
        (this.$refs.modalDetalleFicha.tratamiento = ""),
        (this.$refs.modalDetalleFicha.examenes = ""),
        (this.$refs.modalDetalleFicha.fecha_ingreso = ""),
        (this.$refs.modalDetalleFicha.id_detalle_ficha = ""),
        (this.$refs.modalDetalleFicha.nombre = "");
    },
    limpiar2() {
      (this.$refs.modalDetalleFicha.motivo_consulta = ""),
        (this.$refs.modalEditarFicha.enfermedades_personales = ""),
        (this.$refs.modalEditarFicha.consumo = ""),
        (this.$refs.modalEditarFicha.experiencias_tra_acc_otros = ""),
        (this.$refs.modalEditarFicha.antecedentes_salud = ""),
        (this.$refs.modalEditarFicha.anamnesis = ""),
        (this.$refs.modalEditarFicha.diagnostico = ""),
        (this.$refs.modalEditarFicha.indicaciones = ""),
        (this.$refs.modalEditarFicha.tratamiento = ""),
        (this.$refs.modalEditarFicha.examenes = ""),
        (this.$refs.modalEditarFicha.fecha_ingreso = ""),
        (this.$refs.modalEditarFicha.id_detalle_ficha = ""),
        (this.$refs.modalEditarFicha.nombre = "");
    },
    abrirDetalleFicha(rut, id_detalle_ficha, fecha_ingreso, nombre) {
      // console.log(rut, id_detalle_ficha, fecha_ingreso, nombre)
      this.$refs.modalDetalleFicha.modalSync = true;
      this.$refs.modalDetalleFicha.rut = rut;
      this.$refs.modalDetalleFicha.fecha_ingreso = fecha_ingreso;
      this.$refs.modalDetalleFicha.id_detalle_ficha = id_detalle_ficha;
      this.$refs.modalDetalleFicha.nombre = nombre;
      this.$refs.modalDetalleFicha.obtenerDetalleFicha(rut, id_detalle_ficha);
    },
    editarDetalleFicha(rut, id_detalle_ficha, fecha_ingreso, nombre) {
      this.$refs.modalEditarFicha.modalSync = true;
      this.$refs.modalEditarFicha.rut = rut;
      this.$refs.modalEditarFicha.fecha_ingreso = fecha_ingreso;
      this.$refs.modalEditarFicha.id_detalle_ficha = id_detalle_ficha;
      this.$refs.modalEditarFicha.nombre = nombre;
      this.$refs.modalEditarFicha.obtenerDetalleFicha(rut, id_detalle_ficha);
    },

    //formato para fechas
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY/MM/DD");
      }
    },

    formatDate2(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },

    obtenerListaFicha() {
      let me = this;
      console.log(this.formatDate(me.date));
      me.loading = true;
      return new Promise((resolve, reject) => {
        this.$axios
          .get(this.server_api + "/obtener_lista_ficha", {
            headers: {
              Authorization: `Bearer ${this.auth.access_token}`,
            },
          })
          .then(function (response) {
            me.lista_ficha = response.data;
            me.loading = false;
            resolve(response);
            me.closeLoading();
          })
          .catch(function (error) {
            me.loading = false;
            Swal.fire({
              target: document.getElementById("form-modal"),
              icon: "error",
              title: "¡ Error !",
              text: "Se ha producido un error, vuelva a intentarlo más tarde",
            });
            reject(error);
          });
      });
    },
  },

  mounted() {
    this.obtenerListaFicha();
  },

  computed: {
    formattedDate() {
      return this.formatDate(this.date);
    },

    formattedDate2() {
      return this.formatDate2(this.date);
    },

    ...mapState(["server_api", "auth"]),
  },
};
</script>
<style lang="scss" scoped>
.th_motivo_diag {
  max-width: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.th_ficha {
  max-width: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>