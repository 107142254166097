<template>
  <div class="content">
    <div class="md-layout" id="elemento">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <form v-on:submit.prevent="checkRut()">
          <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
              <h4 class="title">FICHA DE INGRESO</h4>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="col">
                  <div class="card border-light mb-3 col" style="width: 500">
                    <div class="card-body">
                      <div class="row" v-loading="loading">
                        <label class="form-label">RUT</label>
                        <div class="col-sm-4">
                          <el-input
                            v-model="rut"
                            @keyup.native="formatRut"
                            name="rut"
                            maxlength="10"
                            clearable
                            required
                            @keyup.native.enter="checkRut()"
                          >
                          </el-input>
                        </div>
                        <div class="col-sm-2">
                          <el-button type="primary" @click.native="checkRut()"
                            >BUSCAR</el-button
                          >
                        </div>
                        <div class="col-sm-3">
                          <el-button
                            v-if="paciente[0] != null"
                            type="success"
                            @click.native="
                              abrirModalFichasAnteriores(paciente[0].rut)
                            "
                            >Ver fichas anteriores</el-button
                          >
                        </div>
                        <div v-if="div_rut == true" class="col-sm-6 pt-4">
                          <b>
                            <p class="text-danger">{{ mensaje_rut }}</p></b
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--   -->
                <div class="w-100" v-if="formulario == true">
                  <div class="col-md-12">
                    <h4>I. DATOS DE IDENTIFICACIÓN</h4>
                  </div>

                  <div class="card col">
                    <div class="card-body">
                      <div class="col-md-12">
                        <label class="form-label">NOMBRE COMPLETO </label>
                        <el-input v-model="nombre" clearable> </el-input>
                        <p class="text-danger">{{ nombre_error }}</p>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <label class="form-label"
                              >FECHA DE NACIMIENTO</label
                            >
                            <div class="row">
                              <el-date-picker
                                class="col-sm-12"
                                v-model="fecha_nacimiento"
                                type="date"
                                placeholder="Seleccione fecha"
                                format="dd-MM-yyyy"
                              >
                              </el-date-picker>
                              <p class="text-danger">
                                {{ fecha_nacimiento_error }}
                              </p>
                            </div>
                          </div>
                          <div class="col-2">
                            <label class="form-label">EDAD</label>
                            <el-input v-model="edad" clearable> </el-input>
                            <p class="text-danger">{{ edad_error }}</p>
                          </div>
                          <div class="col-4">
                            <label class="form-label">GÉNERO</label>
                            <div class="row">
                              <el-select
                                v-model="id_genero"
                                placeholder="Género"
                                class="col-12"
                              >
                                <el-option
                                  v-for="item in genero"
                                  :key="item.id_genero"
                                  :label="item.nombre_genero"
                                  :value="item.id_genero"
                                >
                                </el-option>
                              </el-select>
                              <p class="text-danger">{{ id_genero_error }}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="row">
                          <div class="col-2">
                            <label class="form-label">ESTADO CIVIL</label>
                            <div class="row">
                              <el-select
                                v-model="id_estado_civil"
                                placeholder="Estado civil"
                                class="col-12"
                              >
                                <el-option
                                  v-for="item in estado_civil"
                                  :key="item.id_estado_civil"
                                  :label="item.nombre_estado"
                                  :value="item.id_estado_civil"
                                >
                                </el-option>
                              </el-select>
                              <p class="text-danger">
                                {{ id_estado_civil_error }}
                              </p>
                            </div>
                          </div>

                          <div class="col-2">
                            <label class="form-label">PARTO DE TERMINO</label>
                            <el-input v-model="parto_termino"></el-input>
                          </div>
                          <div class="col-2">
                            <label class="form-label">PARTOS PREMATUROS</label>
                            <el-input v-model="parto_prematuro"></el-input>
                          </div>
                          <div class="col-2">
                            <label class="form-label">ABORTOS</label>
                            <el-input v-model="abortos"></el-input>
                          </div>
                          <div class="col-2">
                            <label class="form-label">MORTINATO</label>
                            <el-input v-model="mortinato"></el-input>
                          </div>
                          <div class="col-2">
                            <label class="form-label">HIJOS VIVOS</label>
                            <el-input v-model="hijos_vivos"></el-input>
                          </div>
                          <!-- <div class="col-2">
                            <label class="form-label">HIJOS</label>
                            <div>
                              <el-radio v-model="hijos" value="Si" label="Si"
                                >Si</el-radio
                              >
                              <el-radio v-model="hijos" value="No" label="No"
                                >No</el-radio
                              >
                              <p class="text-danger">
                                {{ hijos_error }}
                              </p>
                            </div>
                          </div> -->
                          <!-- <div class="col-4" v-if="hijos == 'Si'">
                            <label class="form-label">N° DE HIJOS</label>
                            <el-input v-model="cantidad_hijos" clearable>
                            </el-input>
                            <p class="text-danger">
                              {{ cantidad_hijos_error }}
                            </p>
                          </div> -->
                          <!-- <div class="col-4" v-else-if="hijos == ''">
                            <label class="form-label">N° DE HIJOS</label>
                            <el-input
                              v-model="cantidad_hijos"
                              disabled
                              clearable
                            >
                            </el-input>
                            <p class="text-danger">
                              {{ cantidad_hijos_error }}
                            </p>
                          </div> -->

                          <!-- <div class="col-4" v-if="hijos == 'No'">
                            <label class="form-label">ABORTO</label>
                            <div class="row">
                              <el-select
                                v-model="aborto"
                                placeholder="Seleccionar"
                                class="col-12"
                              >
                                <el-option
                                  v-for="item in opcion_aborto"
                                  :key="item.value"
                                  :label="item.nombre"
                                  :value="item.value"
                                >
                                </el-option>
                              </el-select>
                              <p class="text-danger">
                                {{ aborto_error }}
                              </p>
                            </div>
                          </div> -->
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-4">
                            <label class="form-label">PROFESIÓN</label>
                            <el-input v-model="profesion" clearable> </el-input>
                            <p class="text-danger">{{ profesion_error }}</p>
                          </div>
                          <div class="col-2">
                            <label class="form-label">PESO</label>
                            <el-input v-model="peso" maxlength="7" clearable>
                            </el-input>
                            <p class="text-danger">{{ peso_error }}</p>
                          </div>
                          <div class="col-2">
                            <label class="form-label">TALLA</label>
                            <el-input
                              v-model="talla"
                              @keyup.native="formatTalla"
                              maxlength="4"
                              focus
                              format
                              clearable
                            >
                            </el-input>
                            <p class="text-danger">{{ talla_error }}</p>
                          </div>
                          <div class="col-2">
                            <label class="form-label">IMC</label>
                            <el-input
                              v-model="imc"
                              clearable
                              @keyup.native="calcularIMC"
                            >
                            </el-input>
                          </div>
                          <div class="col-2">
                            <label class="form-label">PRESION</label>
                            <el-input
                              v-model="presion"
                              maxlength="7"
                              @keyup.native="formatPresion"
                              format
                              clearable
                            >
                            </el-input>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <label class="form-label">DIRECCIÓN PARTICULAR</label>
                        <el-input v-model="direccion" clearable> </el-input>
                        <p class="text-danger">{{ direccion_error }}</p>
                      </div>
                      <div class="col">
                        <div class="row">
                          <div class="col-sm">
                            <label class="form-label">TELEFONO</label>
                            <el-input v-model="telefono" clearable> </el-input>
                            <!-- <p class="text-danger">{{ telefono_error }}</p> -->
                          </div>
                          <div class="col-sm">
                            <label class="form-label">EMAIL</label>
                            <el-input
                              v-model="email"
                              clearable
                              @keyup.native="formatMail"
                            >
                            </el-input>
                            <p class="text-danger">{{ email_error }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <h4>II. ÁREA PERSONALES Y FAMILIAR</h4>
                  </div>

                  <div class="card col">
                    <div class="card-body">
                      <div class="col-md-12">
                        <label class="form-label">A. MOTIVO CONSULTA</label>
                        <el-input
                          type="textarea"
                          id="text_area"
                          v-model="motivo_consulta"
                        ></el-input>
                      </div>
                      <div class="col-md-12">
                        <label class="form-label">B. ANTECEDENTES</label>
                      </div>
                      <!--  <div class="col-md-12">
                        <label class="form-label"
                          >1. ENFERMEDADES PERSONALES Y/O FAMILIARES</label
                        >
                        <el-input
                          v-model="enfermedades_personales"
                          clearable
                          type="textarea"
                          id="text_area"
                        >
                        </el-input>
                      </div> -->
                      <div class="col-md-12">
                        <label class="form-label">1. CONSUMO</label>
                        <div>
                          <el-checkbox-group v-model="consumo">
                            <el-checkbox label="Alcohol"></el-checkbox>
                            <el-checkbox label="Drogas"></el-checkbox>
                            <el-checkbox label="Cigarro"></el-checkbox>
                            <el-checkbox label="Otros"></el-checkbox>
                          </el-checkbox-group>
                        </div>
                      </div>
                      <!-- <div class="col-md-12">
                        <label class="form-label"
                          >3. EXPERIENCIAS TRAUMÁTICAS, ACCIDENTES,
                          OTROS:</label
                        >
                        <el-input
                          v-model="experiencias_tra_acc_otros"
                          id="text_area"
                          clearable
                          type="textarea"
                        >
                        </el-input>
                      </div> -->
                      <!-- <div class="col-md-12">
                        <label class="form-label"
                          >4. ANTECEDENTES DE SALUD</label
                        >
                        <el-input
                          v-model="antecedentes_salud"
                          id="text_area"
                          type="textarea"
                          clearable
                        >
                        </el-input>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-md-12">
                    <h4>III. ANAMNESIS</h4>
                  </div>

                  <div class="card col">
                    <div class="card-body">
                      <div class="col-12">
                        <el-input
                          type="textarea"
                          id="anamnesis"
                          v-model="anamnesis"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <h4>IV. DIAGNOSTICO</h4>
                  </div>
                  <div class="card col">
                    <div class="card-body">
                      <div class="col-12">
                        <el-input
                          type="textarea"
                          id="text_area"
                          v-model="diagnostico"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-12">
                    <h4>V. INDICACIONES</h4>
                  </div> -->
                  <!-- <div class="card col">
                    <div class="card-body">
                      <div class="col-12">
                        <el-input
                          type="textarea"
                          id="text_area"
                          v-model="indicaciones"
                        ></el-input>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-md-12">
                    <h4>V. TRATAMIENTO</h4>
                  </div>
                  <div class="card col">
                    <div class="card-body">
                      <div class="col-12">
                        <el-input
                          type="textarea"
                          id="text_area"
                          v-model="tratamiento"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <h4>VI. EXAMENES SOLICITADOS</h4>
                  </div>
                  <div class="card col">
                    <div class="card-body">
                      <div class="col-12">
                        <el-input
                          type="textarea"
                          id="text_area"
                          v-model="examenes"
                        ></el-input>
                      </div>
                    </div>
                  </div>

                  <br />
                  <div class="col-12">
                    <div class="col-12">
                      <el-button type="success" v-on:click="crearPaciente()"
                        >REGISTRAR</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    ></div>
    <div class="container">
      <modalFichasAnteriores ref="modalFichasAnteriores">
      </modalFichasAnteriores>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import swal from "sweetalert2";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { mapState } from "vuex";

import ModalFichasAnteriores from "@/pages/Modals/ModalFichasAnteriores.vue";

window.Swal = swal;
export default {
  components: {
    modalFichasAnteriores: ModalFichasAnteriores,
  },
  name: "ficha",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "green",
    },
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      estado_civil: [],
      genero: [],
      loading: false,
      input: "",

      /* opcion_aborto: [
        {
          value: "Si",
          nombre: "Si",
        },
        {
          value: "No",
          nombre: "No",
        },
      ],*/
      aborto: "",

      formulario: false,
      id_estado_civil: "",
      id_genero: null,
      div_rut: false,
      mensaje_rut: "",
      rut: "",
      nombre: "",
      fecha_nacimiento: "",
      edad: "",
      hijos: "",
      cantidad_hijos: "",
      profesion: "",
      peso: "",
      talla: 0,
      imc: "",
      direccion: "",
      telefono: "",
      parto_termino: 0,
      parto_prematuro: 0,
      abortos: 0,
      mortinato: 0,
      hijos_vivos: 0,
      presion: "",

      email: "",
      motivo_consulta: "",
      enfermedades_personales: "",
      consumo: [],
      experiencias_tra_acc_otros: "",
      antecedentes_salud: "",
      anamnesis: "",
      diagnostico: "",
      indicaciones: "",
      tratamiento: "",
      examenes: "",
      rut_error: "",
      nombre_error: "",
      fecha_nacimiento_error: "",
      /* edad_error: "",
      hijos_error: "",
      cantidad_hijos_error: "", */
      profesion_error: "",
      peso_error: "",
      aborto_error: "",
      talla_error: "",
      imc_error: 0,
      direccion_error: "",
      /* telefono_error: "",*/
      email_error: "",
      id_estado_civil_error: "",
      id_genero_error: null,
      lista_errores: [],
      paciente: [],
      datos_paciente: [],
      activeLoading: false,
    };
  },
  methods: {
    /*   calculo imc segun datos registrados */
    calcularIMC() {
      let cambioTalla = this.talla * 100;
      let peso = 0;
      let talla = 0;
      let imc = 0;
      peso = Math.round(this.peso);
      talla = Math.round(cambioTalla) / 100;
      imc = peso / Math.pow(talla, 2);

      this.imc = Number(imc.toFixed(1));
    },

    //formato presion
    formatPresion() {
      var num = this.presion.replace(/\./g, "");
      console.log(this.presion);
      /* if (num == "") {
        return (this.imc = 0);
      } else if (!parseInt(num)) {
        return (this.imc = 0);
      } else { */
      num = num
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1/");
      num = num.split("").reverse().join("").replace(/^[\.]/, "");
      this.presion = num;
      // }
    },

    //formato para estatura (talla)
    formatTalla() {
      var num = this.talla.replace(/\./g, "");
      console.log(this.talla);
      if (num == "") {
        return (this.imc = 0);
      } else if (!parseInt(num)) {
        return (this.imc = 0);
      } else {
        num = num
          .toString()
          .split("")
          .reverse()
          .join("")
          .replace(/(?=\d*\.?)(\d{2})/g, "$1.");
        num = num.split("").reverse().join("").replace(/^[\.]/, "");
        this.talla = num;
        this.calcularIMC();
      }
    },

    openLoading() {
      this.activeLoading = true;
      this.$vs.loading();
    },
    closeLoading() {
      this.activeLoading = false;
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },

    //desplazar a principio de página, se llama cuando existen errores en el formulario
    moveUp() {
      window.scrollTo(100, 0);
    },

    //se llama cuando se ingresa el rut, si el paciente existe, llenara los campos del formulario
    obtenerPaciente() {
      let me = this;
      me.loading = true;
      me.mensaje_rut = "";
      return new Promise((resolve, reject) => {
        axios
          .get(this.server_api + "/obtener_paciente?rut=" + this.rut, {
            headers: {
              Authorization: `Bearer ${this.auth.access_token}`,
            },
          })
          .then(function (response) {
            me.paciente = response.data;
            if (me.paciente.length == 1) {
              me.formulario = true;
              me.nombre = me.paciente[0].nombre;
              me.fecha_nacimiento = me.paciente[0].fecha_nacimiento;
              me.edad = me.paciente[0].edad;
              me.hijos = me.paciente[0].hijos;
              me.cantidad_hijos = me.paciente[0].cantidad_hijos;
              me.profesion = me.paciente[0].profesion;
              me.peso = me.paciente[0].peso;
              me.presion = me.paciente[0].presion;
              me.aborto = me.paciente[0].aborto;
              me.talla = me.paciente[0].talla;
              me.imc = me.paciente[0].imc;
              me.direccion = me.paciente[0].direccion;
              me.telefono = me.paciente[0].telefono;
              me.parto_termino = me.paciente[0].parto_termino;
              me.parto_prematuro = me.paciente[0].parto_prematuro;
              me.abortos = me.paciente[0].abortos;
              me.mortinato = me.paciente[0].mortinato;
              me.hijos_vivos = me.paciente[0].hijos_vivos;
              me.email = me.paciente[0].email;
              me.id_genero = me.paciente[0].id_genero;
              me.id_estado_civil = me.paciente[0].id_estado_civil;
              me.loading = false;
              resolve(response);
            } else {
              me.nombre = "";
              me.fecha_nacimiento = "";
              me.edad = "";
              me.hijos = "";
              me.cantidad_hijos = "";
              me.profesion = "";
              me.peso = "";
              me.talla = "";
              me.imc = "";
              me.direccion = "";
              me.telefono = "";
              me.email = "";
              me.id_genero = "";
              me.id_estado_civil = "";
              me.parto_termino = 0;
              me.parto_prematuro = 0;
              me.abortos = 0;
              me.mortinato = 0;
              me.hijos_vivos = 0;
              me.presion = "";
              me.mensaje_rut =
                "Favor llenar los campos requeridos para el nuevo paciente *";
              me.formulario = true;
              me.loading = false;
            }
          })
          .catch(function (error) {
            me.loading = false;
            Swal.fire({
              target: document.getElementById("form-modal"),
              icon: "error",
              title: "¡ Error !",
              text: "Se ha producido un error, vuelva a intentarlo más tarde",
            });
            reject(error);
          });
      });
    },

    obtenerEstado() {
      let me = this;
      return new Promise((resolve, reject) => {
        axios
          .get(this.server_api + "/obtener_estado", {
            headers: {
              Authorization: `Bearer ${this.auth.access_token}`,
            },
          })
          .then(function (response) {
            me.estado_civil = response.data;
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    obtenerGenero() {
      let me = this;
      return new Promise((resolve, reject) => {
        axios
          .get(this.server_api + "/obtener_genero", {
            headers: {
              Authorization: `Bearer ${this.auth.access_token}`,
            },
          })
          .then(function (response) {
            me.genero = response.data;
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },

    //validación de rut, si es valido se llamará a obtenerPaciente
    checkRut() {
      let me = this;
      me.div_rut = true;
      if (!me.rut) {
        me.mensaje_rut = "El rut es requerido";
        me.formulario = false;
      } else if (!me.validaRut(me.rut)) {
        me.mensaje_rut = "El rut ingresado no es válido";
        me.formulario = false;
      } else {
        me.obtenerPaciente();
      }
    },
    checkForm() {
      this.mensaje_rut = "";
      this.nombre_error = "";
      this.fecha_nacimiento_error = "";
      this.id_genero_error = "";
      this.id_estado_civil_error = "";
      this.edad_error = "";
      /* this.hijos_error = "";
      this.cantidad_hijos_error = "";
      this.aborto_error = ""; */
      this.profesion_error = "";
      this.peso_error = "";
      this.talla_error = "";
      this.imc_error = "";
      this.direccion_error = "";
      /* this.telefono_error = ""; */
      /*   this.email_error = ""; */

      let me = this;
      if (
        this.rut &&
        this.nombre &&
        this.fecha_nacimiento &&
        this.id_genero &&
        this.id_estado_civil &&
        this.edad &&
        this.profesion &&
        this.peso &&
        this.talla &&
        this.imc &&
        this.direccion &&
        this.email
      )
        return true;

      this.lista_errores = [];
      if (!me.rut) {
        this.mensaje_rut = "* El rut es requerido";
        this.lista_errores.push(1);
      } else if (!this.validaRut(this.rut)) {
        this.mensaje_rut = "* El rut ingresado no es válido";
        this.lista_errores.push(1);
      }
      if (!this.nombre) {
        this.nombre_error = "Debe ingresar nombre del paciente";
        this.lista_errores.push(1);
      }
      if (!this.fecha_nacimiento) {
        this.fecha_nacimiento_error =
          "Debe seleccionar, fecha de nacimiento del paciente";
        this.lista_errores.push(1);
      }
      /* if (!this.email) {
        this.email_error = "El email es requerido";
        this.lista_errores.push(1);
      } */
      /* if (!this.validEmail(this.email)) {
        this.email_error = "El correo electrónico debe ser válido";
        this.lista_errores.push(1);
      } */
      if (!this.id_genero) {
        this.id_genero_error = "Debe seleccionar género del paciente";
        this.lista_errores.push(1);
      }
      if (!this.id_estado_civil) {
        this.id_estado_civil_error =
          "Debe seleccionar estado civil del paciente";
        this.lista_errores.push(1);
      }
      if (!this.edad) {
        this.edad_error = "Debe ingresar la edad del paciente";
        this.lista_errores.push(1);
      }
      if (!parseInt(this.edad)) {
        this.edad_error = "Debe ingresar número";
        this.lista_errores.push(1);
      }
      /* if (!this.hijos) {
        this.hijos_error = "Indique si el paciente tiene hijos";
        this.lista_errores.push(1);
      } */
      /* if (this.hijos == "Si") {
        if (!this.cantidad_hijos) {
          this.cantidad_hijos_error =
            "Paciente tiene hijos, indique la cantidad";
          this.lista_errores.push(1);
        }
        if (!parseInt(this.cantidad_hijos)) {
          this.cantidad_hijos_error = "Debe ingresar número";
          console.log("no number");
          this.lista_errores.push(1);
        }
      } */
      /* if (this.hijos == "No") {
        if (!this.aborto) {
          this.aborto_error =
            "Paciente no tienes hijos, indique si ha tenido aborto";
          this.lista_errores.push(1);
        }
      } */
      if (!this.profesion) {
        this.profesion_error = "Debe indicar si el paciente tiene ocupación";
        this.lista_errores.push(1);
      }
      if (!this.imc) {
        this.imc_error = "Debe indicar IMC del paciente";
        this.lista_errores.push(1);
      }
      if (!this.peso) {
        this.peso_error = "Debe indicar el peso del paciente";
        this.lista_errores.push(1);
      }
      if (!parseInt(this.peso)) {
        this.peso_error = "Debe ingresar número";
        this.lista_errores.push(1);
      }
      if (!this.talla) {
        this.talla_error = "Debe indicar talla del paciente";
        this.lista_errores.push(1);
      }
      if (this.talla) {
        let talla = this.talla * 100;
        if (!parseInt(talla)) {
          this.talla_error = "Debe ingresar número";
          this.lista_errores.push(1);
        }
      }
      if (!this.direccion) {
        this.direccion_error = "Debe indicar dirección del paciente";
        this.lista_errores.push(1);
      }
      /*  if (!this.telefono) {
        this.telefono_error = "Debe número de teléfono del paciente";
        this.lista_errores.push(1);
      } */

      if (this.lista_errores.length > 0) {
        Swal.fire({
          icon: "error",
          title: "¡Atención!",
          text: "Faltan datos del paciente",
        }).then(function () {
          window.scrollTo({
            top: 500,
            behavior: "auto",
          });
        });

        //en caso de error, la página nos llevrá a principio de formulario
        this.moveUp();
        //frena la ejecución del código, en caso de un error
        preventDefault();
      }
    },

    formato_fecha(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDD");
      }
    },
    crearPaciente() {
      if (this.hijos == "No") {
        this.cantidad_hijos = "";
      }
      let me = this;
      me.checkForm();
      return new Promise((resolve, reject) => {
        axios
          .post(
            this.server_api + "/crear_paciente",
            {
              rut: this.rut,
              nombre: this.nombre,
              fecha_nacimiento: this.formato_fecha(this.fecha_nacimiento),
              edad: this.edad,
              id_genero: this.id_genero,
              id_estado_civil: this.id_estado_civil,
              /* hijos: this.hijos,
              cantidad_hijos: this.cantidad_hijos, */
              profesion: this.profesion,
              presion: this.presion,
              peso: this.peso,
              talla: this.talla,
              /* aborto: this.aborto, */
              parto_termino: this.parto_termino,
              parto_prematuro: this.parto_prematuro,
              abortos: this.abortos,
              mortinato: this.mortinato,
              hijos_vivos: this.hijos_vivos,
              imc: this.imc,
              direccion: this.direccion,
              telefono: this.telefono,
              email: this.email,
              motivo_consulta: this.motivo_consulta,
              /*  enfermedades_personales: this.enfermedades_personales, */
              consumo: this.consumo,
              /* experiencias_tra_acc_otros: this.experiencias_tra_acc_otros, */
              /* antecedentes_salud: this.antecedentes_salud, */
              anamnesis: this.anamnesis,
              diagnostico: this.diagnostico,
              /* indicaciones: this.indicaciones, */
              tratamiento: this.tratamiento,
              examenes: this.examenes,
            },
            {
              headers: {
                Authorization: `Bearer ${this.auth.access_token}`,
              },
            }
          )
          .then(function (response) {
            Swal.fire("¡ Exito !", response.data, "success");
            me.limpiar();
            me.closeLoading();
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire(
              "¡ Error !",
              "Se ha producido un error, vuelva a intentarlo más tarde",
              "error"
            );
            reject(error);
            me.closeLoading();
          });
        me.closeLoading();
      });
    },

    validaRut(rutCompleto) {
      rutCompleto = rutCompleto.replace("‐", "-");
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";

      return this.dv(rut) == digv;
    },
    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },

    formatRut() {
      /*  var validar_rut = this.validaRut();
      if (validar_rut == true) {
        if (
          this.rut.charAt(0) === "k" ||
          this.rut.charAt(0) === "k" ||
          this.rut.charAt(0) === "0"
        ) {
          this.rut = "";
        } */
      let clearRut =
        typeof this.rut === "string"
          ? this.rut.replace(/[^0-9kK]+/g, "").toUpperCase()
          : ""; // limpiamos la variable rut
      if (clearRut.length <= 1) {
        return false;
      }
      var result =
        clearRut.slice(-4, -1) + "-" + clearRut.substr(clearRut.length - 1);
      for (var i = 4; i < clearRut.length; i += 3) {
        result = clearRut.slice(-3 - i, -i) + "" + result;
      }
      this.rut = result;
      let rut = this.rut;
      if (typeof rut !== "string") {
        return false;
      }
      rut =
        typeof this.rut === "string"
          ? this.rut.replace(/[^0-9kK]+/g, "").toUpperCase()
          : "";
      var rutDigits = parseInt(rut.slice(0, -1), 10);
      var m = 0;
      var s = 1;
      while (rutDigits > 0) {
        s = (s + (rutDigits % 10) * (9 - (m++ % 6))) % 11;
        rutDigits = Math.floor(rutDigits / 10);
      }
      var checkDigit = s > 0 ? String(s - 1) : "K";
      if (checkDigit === rut.slice(-1)) {
        this.mensaje_rut = "";
      } else {
        this.mensaje_rut = true;
      }

      var validar_rut = this.validaRut(this.rut);
      if (validar_rut == false) {
        this.div_rut = true;
        this.mensaje_rut = "Rut incompleto o erróneo";
      } else {
        this.div_rut = false;
      }
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    formatMail() {
      var validar_mail = this.validEmail(this.email);
      if (validar_mail == false) {
        this.email_error = "Email incompleto o erróneo";
      } else {
        this.email_error = "";
      }
    },

    limpiar() {
      let me = this;

      me.mensaje_rut = "";
      me.rut = "";
      me.nombre = "";
      me.fecha_nacimiento = "";
      me.edad = "";
      me.hijos = "";
      me.cantidad_hijos = "";
      me.profesion = "";
      me.peso = "";
      me.talla = "";
      me.imc = "";
      me.direccion = "";
      me.telefono = "";
      me.email = "";
      me.motivo_consulta = "";
      me.enfermedades_personales = "";
      me.consumo = [];
      me.experiencias_tra_acc_otros = "";
      me.antecedentes_salud = "";
      me.anamnesis = "";
      me.diagnostico = "";
      me.indicaciones = "";
      me.tratamiento = "";
      me.examenes = "";
      me.parto_termino = 0;
      me.parto_prematuro = 0;
      me.abortos = 0;
      me.mortinato = 0;
      me.hijos_vivos = 0;
      me.presion = "";
      me.rut_error = "";
      me.nombre_error = "";
      me.fecha_nacimiento_error = "";
      me.edad_error = "";
      /* me.hijos_error = "";
      me.cantidad_hijos_error = ""; */
      me.profesion_error = "";
      me.peso_error = "";
      /* me.aborto_error = ""; */
      me.talla_error = "";
      me.imc_error = "";
      me.direccion_error = "";
      /* me.telefono_error = "";
      me.email_error = ""; */
      me.id_estado_civil_error = "";
      me.id_genero_error = null;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
    abrirModalFichasAnteriores(rut) {
      this.$refs.modalFichasAnteriores.modalSync = true;
      this.$refs.modalFichasAnteriores.obtenerListaFichas(rut);

      //this.$refs.modalDetalleFicha.nombre = nombre;
      //this.$refs.modalDetalleFicha.obtenerDetalleFicha(rut, id_detalle_ficha);
    },
  },

  mounted() {
    this.obtenerGenero();
    this.obtenerEstado();
  },

  computed: {
    formattedDate() {
      return this.formatDate(this.date);
    },

    ...mapState(["server_api", "auth"]),
  },
};
</script>
<style>
button {
  float: right;
}

#text_area {
  height: 100px;
}

#anamnesis {
  height: 200px;
}

#text_area_antecedentes {
  height: 100px;
}
</style>
