<template>
  <div style=" width=200px">
    <el-dialog
      title="Detalle Ficha"
      :visible.sync="modalSync"
      width="70%"
      top="10px"
      :before-close="lostFocusModal"
    >
      <!-- ----------------------------------------------------------------------------------- -->
      <el-table
        v-loading="loading"
        :data="lista_fichas"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="id_detalle_ficha" label="Ficha">
        </el-table-column>
        <el-table-column prop="fecha_ingreso" label="Fecha de ingreso">
        </el-table-column>
        <el-table-column prop="motivo_consulta" label="Motivo consulta">
        </el-table-column>
        <el-table-column label="Acciones" width="100">
          <template slot-scope="scope">
            <el-button
              type="success"
              @click="verDetalle(scope.$index, scope.row)"
              ><i class="fa fa-eye"></i> Ver</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <div class="container">
      <modalDetalleFichaAnterior ref="modalDetalleFichaAnterior">
      </modalDetalleFichaAnterior>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import swal from "sweetalert2";
import { mapState } from "vuex";
import moment from "moment";
import ModalDetalleFichaAnterior from "@/pages/Modals/ModalDetalleFichaAnterior.vue";
export default {
  components: {
    modalDetalleFichaAnterior: ModalDetalleFichaAnterior,
  },
  data() {
    return {
      modalSync: false,
      rut: "",
      loading: true,
      detalle_fichas: [],
      motivo_consulta: "",
      lista_fichas: [],
      /* enfermedades_personales: "", */
      consumo: "",
      /* experiencias_tra_acc_otros: "", */
      /* antecedentes_salud: "", */
      anamnesis: "",
      diagnostico: "",
      /* indicaciones: "", */
      tratamiento: "",
      examenes: "",
      fecha_ingreso: "",
      id_detalle_ficha: "",
      nombre: "",
      date: "",
    };
  },

  methods: {
    openLoading() {
      this.$vs.loading();
    },
    closeLoading() {
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    obtenerListaFichas(rut) {
      let me = this;
      console.log(this.formatDate(me.date));
      me.loading = true;
      return new Promise((resolve, reject) => {
        this.$axios
          .get(this.server_api + "/obtener_fichas_rut?rut=" + rut, {
            headers: {
              Authorization: `Bearer ${this.auth.access_token}`,
            },
          })
          .then(function (response) {
            me.lista_fichas = response.data;
            me.loading = false;
            resolve(response);
            me.closeLoading();
          })
          .catch(function (error) {
            me.loading = false;
            Swal.fire({
              target: document.getElementById("form-modal"),
              icon: "error",
              title: "¡ Error !",
              text: "Se ha producido un error, vuelva a intentarlo más tarde",
            });
            reject(error);
          });
      });
    },
    lostFocusModal(done) {
      let me = this;
      this.$confirm("Se cerrará módulo")
        .then((_) => {
          this.cerrarModal();
          done();
        })
        .catch((_) => {});
    },

    verDetalle(index, row) {
      console.log(row);
      this.$refs.modalDetalleFichaAnterior.modalSync = true;
      this.$refs.modalDetalleFichaAnterior.detalle_ficha = row;
    },

    cerrarModal() {
      this.modalSync = false;
    },
  },

  computed: {
    formattedDate() {
      return this.formatDate(this.date);
    },
    ...mapState(["server_api", "auth"]),
  },
  mounted() {
    //  this.obtenerDetalleFicha(this.rut);
  },
};
</script>
<style>
.contenedor {
  justify-content: right;
}
.right {
  float: right;
}
.left {
  float: left;
}
</style>
