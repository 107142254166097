<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title"><b>PACIENTES REGISTRADOS</b></h4>
          </md-card-header>
          <md-card-content>
            <el-table
              v-loading="loading"
              :data="
                lista_paciente.filter(
                  (data) =>
                    !search ||
                    data.rut.toLowerCase().includes(search.toLowerCase())
                )
              "
              style="width: 100%"
            >
              <el-table-column prop="rut" label="Rut" width="120">
              </el-table-column>
              <el-table-column
                sortable
                prop="nombre"
                label="Nombre"
                width="500"
              >
              </el-table-column>
              <el-table-column
                class="w-100"
                prop="fecha_nacimiento"
                label="Fecha de nacimiento"
                ><template slot-scope="scope">
                  {{ formatDate(scope.row.fecha_nacimiento) }}
                </template>
              </el-table-column>
              <el-table-column align="right">
                <template slot="header" slot-scope="scope">
                  <el-input
                    v-model="search"
                    placeholder="Ingrese Rut"
                  /> </template
                ><template slot-scope="scope">
                  <button
                    class="btn btn-outline-warning"
                    title="Editar"
                    @click="
                      limpiar2(),
                        editarDetallePaciente(
                          scope.row.id_paciente,
                          scope.row.id_genero,
                          scope.row.id_estado_civil
                        )
                    "
                  >
                    Editar
                    <i class="far fa-edit"></i>
                  </button>
                  <button
                    class="btn btn-outline-success"
                    @click="
                      limpiar(), abrirDetallePaciente(scope.row.id_paciente)
                    "
                    title="Ver"
                  >
                    Ver
                    <i class="fa fa-eye"></i>
                  </button>
                </template>
              </el-table-column>
            </el-table>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <div class="container">
      <modalDetallePaciente ref="modalDetallePaciente"> </modalDetallePaciente>
      <modalEditarPaciente ref="modalEditarPaciente"> </modalEditarPaciente>
    </div>
  </div>
</template>

<script>
import ModalDetallePaciente from "@/pages/Modals/ModalDetallePaciente.vue";
import ModalEditarPaciente from "@/pages/Modals/ModalEditarPaciente.vue";
import { ref } from "vue";
import axios from "axios";
import swal from "sweetalert2";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    modalDetallePaciente: ModalDetallePaciente,
    modalEditarPaciente: ModalEditarPaciente,
  },
  name: "lista-pacientes",
  data() {
    return {
      search: "",
      lista_paciente: [],
      total_ficha: "",
      modalDetallePaciente: false,
      modalEditarPaciente: false,
      date: "",
      loading: true,
    };
  },

  methods: {
    openLoading() {
      this.$vs.loading();
    },
    closeLoading() {
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    limpiar() {
      (this.$refs.modalDetallePaciente.nombre = ""),
        (this.$refs.modalDetallePaciente.rut = ""),
        (this.$refs.modalDetallePaciente.fecha_nacimiento = ""),
        (this.$refs.modalDetallePaciente.edad = ""),
        (this.$refs.modalDetallePaciente.hijos = ""),
        (this.$refs.modalDetallePaciente.cantidad_hijos = ""),
        (this.$refs.modalDetallePaciente.profesion = ""),
        (this.$refs.modalDetallePaciente.peso = ""),
        (this.$refs.modalDetallePaciente.talla = ""),
        (this.$refs.modalDetallePaciente.imc = ""),
        (this.$refs.modalDetallePaciente.direccion = ""),
        (this.$refs.modalDetallePaciente.telefono = ""),
        (this.$refs.modalDetallePaciente.email = ""),
        (this.$refs.modalDetallePaciente.nombre_genero = ""),
        (this.$refs.modalDetallePaciente.nombre_estado = "");
    },

    limpiar2() {
      (this.$refs.modalEditarPaciente.nombre = ""),
        (this.$refs.modalEditarPaciente.rut = ""),
        (this.$refs.modalEditarPaciente.fecha_nacimiento = ""),
        (this.$refs.modalEditarPaciente.edad = ""),
        (this.$refs.modalEditarPaciente.hijos = ""),
        (this.$refs.modalEditarPaciente.cantidad_hijos = ""),
        (this.$refs.modalEditarPaciente.profesion = ""),
        (this.$refs.modalEditarPaciente.peso = ""),
        (this.$refs.modalEditarPaciente.talla = ""),
        (this.$refs.modalEditarPaciente.imc = ""),
        (this.$refs.modalEditarPaciente.direccion = ""),
        (this.$refs.modalEditarPaciente.telefono = ""),
        (this.$refs.modalEditarPaciente.email = ""),
        (this.$refs.modalEditarPaciente.id_genero = ""),
        (this.$refs.modalEditarPaciente.id_estado_civil = "");
    },
    abrirDetallePaciente(id_paciente) {
      this.$refs.modalDetallePaciente.modalSync = true;
      this.$refs.modalDetallePaciente.obtenerDetallePaciente(id_paciente);
    },
    editarDetallePaciente(id_paciente, id_genero, id_estado_civil) {
      this.$refs.modalEditarPaciente.modalSync = true;
      this.$refs.modalEditarPaciente.id_paciente = id_paciente;
      this.$refs.modalEditarPaciente.obtenerDetallePaciente(id_paciente);
      this.$refs.modalEditarPaciente.id_genero = id_genero;
      this.$refs.modalEditarPaciente.id_estado_civil = id_estado_civil;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },

    obtenerListaPaciente() {
      let me = this;
      me.loading = true;
      return new Promise((resolve, reject) => {
        this.$axios
          .get(this.server_api + "/obtener_lista_paciente", {
            headers: {
              Authorization: `Bearer ${this.auth.access_token}`,
            },
          })
          .then(function (response) {
            me.lista_paciente = response.data;
            me.loading = false;
            resolve(response);
          })
          .catch(function (error) {
            me.loading = false;
            Swal.fire({
              target: document.getElementById("form-modal"),
              icon: "error",
              title: "¡ Error !",
              text: "Se ha producido un error, vuelva a intentarlo más tarde",
            });
            reject(error);
          });
      });
    },
  },

  mounted() {
    this.obtenerListaPaciente();
  },

  computed: {
    formattedDate() {
      return this.formatDate(this.date);
    },

    ...mapState(["server_api", "auth"]),
  },
};
</script>
<style lang="scss" scoped>
</style>